//Importação React
import React from "react";
import { useTranslation } from "react-i18next";
import { RiArrowDownSLine } from "react-icons/ri";

export default function Purpose() {
  const { t } = useTranslation();

  const textos = [
    {
      title: `${t("purpose.title1")}`,
      description: `${t("purpose.description1")}`
    },
    {
      title: `${t("purpose.title2")}`,
      description: `${t("purpose.description2")}`
    },
    {
      title: `${t("purpose.title3")}`,
      description: `${t("purpose.description3")}`
    },
    {
      title: `${t("purpose.title4")}`,
      description: `${t("purpose.description4")}`
    },
    {
      title: `${t("purpose.title5")}`,
      description: `${t("purpose.description5")}`
    },
    {
      title: `${t("purpose.title6")}`,
      description: `${t("purpose.description6")}`
    }
  ];

  return (
    <section className="px-6 lg:px-14 2xl:px-36 py-24 md:py-40" id="purpose">
      <div className="w-full flex flex-col gap-6 md:gap-10">
        <div>
          <h3 className="text-lg md:text-xl font-normal uppercase tracking-widest text-primary mb-3">
            {t("purpose.span")}
          </h3>
          <h2 className="text-neutral-200 font-bold text-3xl md:text-4xl md:w-9/12 leading-normal">
            {t("purpose.title")}
          </h2>
        </div>
        <div className="grid grid-cols-1 gap-5">
          {textos.map(item => (
            <div className="relative overflow-hidden border border-neutral-600 py-2.5 bg-neutral-800 rounded px-5 md:px-7 peer-checked:border-orange-600">
              <label
                htmlFor="pergunta1"
                className="opacity-0 absolute top-0 inset-x-0"
              >
                Expandir pergunta
              </label>
              <input
                type="checkbox"
                id="pergunta1"
                className="peer absolute top-0 inset-x-0 w-full h-full opacity-0 z-10 cursor-pointer"
              />
              <div className="h-16 flex items-center">
                <h4 className="font-medium text-xl text-neutral-200 w-10/12">
                  {item.title}
                </h4>
              </div>
              <div className="absolute top-[21px] right-6 text-neutral-200 transition-transform duration-500 rotate-0 peer-checked:rotate-180 p-2.5 shadow-md rounded-full border border-neutral-600 peer-checked:bg-orange-600 peer-checked:text-neutral-200 peer-checked:border-none">
                <RiArrowDownSLine className="h-5 w-5" />
              </div>
              <div className="overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-max">
                <div className=" mb-5 mt-2 md:mt-0">
                  <p className=" text-lg text-neutral-400">
                    {item.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

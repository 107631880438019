import React from "react";

const inputLabel = ({ label, touched, error, customFeedbackLabel }) => {
  if (touched && error) {
    return <div className={"text-sm font-medium text-red-500"}>{error}</div>;
  }

  if (touched && !error && label) {
    return (
      <div className={"text-sm font-medium text-red-500"}>
        {label} was entered correct
      </div>
    );
  }

  return (
    <div className={"text-sm font-medium text-red-500"}>
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
      {!customFeedbackLabel && <></>}
    </div>
  );
};

const selectLabel = ({ label, touched, error, customFeedbackLabel }) => {
  if (touched && error) {
    return <div className={"text-sm font-medium text-red-500"}>{error}</div>;
  }

  return (
    <div className={"text-sm font-medium text-red-500"}>
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
      {!customFeedbackLabel && label && <></>}
    </div>
  );
};

export function FieldFeedbackLabel({
  label,
  touched,
  error,
  type,
  customFeedbackLabel
}) {
  switch (type) {
    case "text":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    case "email":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    case "password":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    default:
      return selectLabel({ label, touched, error, customFeedbackLabel });
  }
}

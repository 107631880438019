import { Field, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import Input from "../../_layout/_partials/controls/forms/Input";
import { Telefone } from "../../_layout/_partials/controls/forms/Telefone";
import { enviarContato } from "../_redux/contato/contatoCrud";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { RiArrowUpLine } from "react-icons/ri";

export function retirarMascaraCEP(valor) {
  return valor.replace(/[().\s-]+/g, "");
}

export default function Contact() {
  // Validation schema
  const validacao = Yup.object().shape({
    nome: Yup.string().required("Nome é obrigatório"),
    email: Yup.string()
      .email("E-mail inválido")
      .required("E-mail é obrigatório"),
    celular: Yup.string().required("Celular é obrigatório")
  });

  const notify = () =>
    toast.success("E-mail enviado!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });

  const { t } = useTranslation();

  return (
    <>
      <section
        className="flex items-center justify-center px-6 lg:px-14 2xl:px-48 py-20 "
        id="contact"
      >
        <div className="flex flex-col justify-center items-center">
          <div className="w-full flex flex-col justify-center">
            <h3 className="text-lg md:text-xl font-normal uppercase tracking-widest text-primary mb-3 text-center">
              {t("contact.span")}
            </h3>
            <center>
              <h2 className="text-neutral-200 font-bold text-3xl md:text-4xl text-center  md:w-[70%] ">
                {t("contact.title")}
              </h2>
            </center>
          </div>
          <div id="contato" className="md:w-4/6 mt-14 ">
            <Formik
              initialValues={{
                nome: "",
                email: "",
                celular: "",
                mensagem: ""
              }}
              onSubmit={(values, { resetForm }) => {
                values.celular = retirarMascaraCEP(values.celular);
                enviarContato(values);
                resetForm({});
              }}
              validationSchema={validacao}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                resetForm,
                touched,
                errors
              }) => (
                <div>
                  <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-2 gap-y-6 gap-x-3 ">
                      <div className="col-span-2">
                        <Field
                          name="nome"
                          component={Input}
                          label={t("contact.name")}
                          placeholder={t("contact.placeholderName")}
                          className="w-full"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.nome}
                        />
                      </div>

                      <Field
                        name="email"
                        component={Input}
                        label="E-mail"
                        placeholder="email@email.com"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <Field
                        name="celular"
                        component={Telefone}
                        label={t("contact.number")}
                        placeholder="(XX) XXXXX-XXXX"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.celular}
                      />

                      <div className="col-span-2">
                        <label
                          htmlFor="mensagem"
                          className="text-neutral-200 text-sm font-medium"
                        >
                          {t("contact.message")}
                          <span className="text-neutral-400">
                            {t("contact.optional")}
                          </span>
                        </label>
                        <textarea
                          name="mensagem"
                          cols="30"
                          rows="10"
                          placeholder={t("contact.placeholderMessage")}
                          label={t("contact.message")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.mensagem}
                          className={
                            "bg-neutral-800 w-full pl-3 pr-6 mt-2 p-2 text-sm font-regular text-neutral-200 border rounded-md border-neutral-600 hover:bg-neutral-700 focus:outline-none focus:border-primary placeholder:text-neutral-400 "
                          }
                        ></textarea>
                      </div>
                    </div>
                    <div className="flex items-center justify-end mt-4">
                      {values.nome === "" ||
                      values.email === "" ||
                      values.celular === "" ||
                      (errors.email && touched.email) ? (
                        <div className="flex justify-center items-center">
                          <button className=" bg-[#D95B00] rounded cursor-pointer hover:bg-orange-700 transform hover:-translate-y-1 hover:scale-110 transition duration-500">
                            <button
                              type="submit"
                              className={`relative inline-flex group justify-center font-semibold text-sm md:text-base text-neutral-200 items-center px-4 py-2 ransition-transform gap-3`}
                            >
                              <RiArrowUpLine className="w-4 h-4 md:w-6 md:h-6 text-neutral-100 hover:text-neutral-200" />
                              {t("contact.button")}
                            </button>
                          </button>
                        </div>
                      ) : (
                        <div className="flex justify-center items-center">
                          <button className=" bg-[#D95B00] rounded cursor-pointer hover:bg-orange-700 transform hover:-translate-y-1 hover:scale-110 transition duration-500">
                            <button
                              type="submit"
                              onClick={notify}
                              className={`relative inline-flex group justify-center font-semibold text-sm md:text-base text-neutral-200 items-center px-4 py-2 ransition-transform gap-3`}
                            >
                              <RiArrowUpLine className="w-4 h-4 md:w-6 md:h-6 text-neutral-100 hover:text-neutral-200" />
                              {t("contact.button")}
                            </button>
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  ></ToastContainer>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
}

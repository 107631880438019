import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import NumberFormat from "react-number-format";
const getFieldCSSClasses = (touched, errors) => {
  const classes = [
    "bg-neutral-800 w-full h-11 pl-3 pr-8 mt-2 text-sm font-regular text-neutral-200 border rounded border-neutral-600 hover:bg-neutral-700 focus:outline-none focus:border-primary focus:border-2 placeholder:text-neutral-400 focus:bg-neutral-800"
  ];
  if (touched && errors) {
    classes.push("border-2 border-red-500");
  }

  if (touched === true && errors === undefined) {
    classes.push("border-2 border-green-500");
  }

  return classes.join(" ");
};

const getFieldCSSClasses2 = (touched, errors) => {
  const classes = [
    "bg-neutral-800 w-full h-11 pl-3 pr-8 mt-2 text-sm font-regular text-neutral-200 border rounded border-neutral-600 hover:bg-neutral-700 focus:outline-none focus:border-primary focus:border-2 placeholder:text-neutral-400 focus:bg-neutral-800"
  ];
  if (touched && errors) {
    classes.push("border border-red-500");
  }

  if (touched === true && errors === undefined) {
    classes.push("border border-green-500");
  }

  return classes.join(" ");
};

export function Telefone({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  form: { touched, errors },
  type = "text",
  ...props
}) {
  return (
    <>
      <div>
        {label && (
          <label
            htmlFor={label}
            className="text-neutral-200 text-sm font-medium"
          >
            {" "}
            {label}{" "}
          </label>
        )}
        <div>
          <NumberFormat
            format="(##) #####-####"
            type={type}
            {...field}
            {...props}
            className={getFieldCSSClasses(
              touched[field.name],
              errors[field.name]
            )}
          />
          {withFeedbackLabel && form.errors[field.name] && (
            <FieldFeedbackLabel
              error={form.errors[field.name]}
              touched={form.touched[field.name]}
              label={label}
              type={type}
              customFeedbackLabel={customFeedbackLabel}
            />
          )}
        </div>
      </div>
    </>
  );
}

export function Telefone2({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  form: { touched, errors },
  type = "text",
  ...props
}) {
  return (
    <>
      <div>
        {label && (
          <label
            htmlFor={label}
            className="text-gray-500 text-sm font-semibold"
          >
            {" "}
            {label}{" "}
          </label>
        )}
        <div>
          <NumberFormat
            format="(##) #####-####"
            type={type}
            {...field}
            {...props}
            className={getFieldCSSClasses2(
              touched[field.name],
              errors[field.name]
            )}
          />
          {withFeedbackLabel && form.errors[field.name] && (
            <FieldFeedbackLabel
              error={form.errors[field.name]}
              touched={form.touched[field.name]}
              label={label}
              type={type}
              customFeedbackLabel={customFeedbackLabel}
            />
          )}
        </div>
      </div>
    </>
  );
}

const messages = {
  pt: {
    translations: {
      nav: {
        home: "Home",
        services: "Serviços",
        siliconValley: "Silicon Valley",
        purpose: "Propósito",
        team: "Time",
        methodology: "Metodologia",
        contact: "Contato"
      },
      home: {
        span: "Consultoria ágil, design e tecnologia.",
        title:
          "Criamos mudanças <br class='hidden lg:block'/> que realmente <span class='font-extrabold underline decoration-[#414141] decoration-auto'>importam</span>",
        text:
          "Transformamos <span class='text-neutral-200'>metas</span> em realidade de forma <span class='text-neutral-200'>rápida</span> e <span class='text-neutral-200'>sustentável</span>. Ajudamos organizações a definirem direções e alcançarem seus <span class='text-neutral-200'>objetivos</span> com <span class='text-neutral-200'>profissionalismo</span>.",
        button: "Transforme agora"
      },
      segments: {
        span: "Principais Segmentos",
        title: "Descubra nossas áreas de atuação",
        segment1: "Seguros",
        segment2: "Varejo",
        segment3: "Financeiro",
        segment4: "Serviços",
        segment5: "Manufatura",
        segment6: "Energia"
      },
      services: {
        span: "Nossos serviços",
        title: "Conheça nossos serviços e soluções tecnológicas",
        card1Title: "Tecnologia",
        card1Description:
          "Oferecemos serviços de front-end, back-end e mobile, além de análise de dados e APIs para integração de sistemas.",
        card2Title: "Internet",
        card2Description:
          "Desenvolvemos websites, e-commerce e portais de conteúdo, além de estratégias para o posicionamento digital de sua empresa.",
        card3Title: "XP Digitais",
        card3Description:
          "Criamos soluções tecnológicas com UX/UI Design, redesign de jornadas de clientes, desenvolvimento de produtos digitais e front-end para sua empresa.",
        card4Title: "Produtos Digitais",
        card4Description:
          "Desenvolvemos produtos para startups, engenharia de software para web e soluções ágeis de desenvolvimento para impulsionar sua empresa.",
        card5Title: "Cultura Digital",
        card5Description:
          "Transforme sua empresa para a era digital com nosso workshop de disrupção e futuro, promovendo cultura mindset shift e educação corporativa.",
        card6Title: "Engajar e Evoluir",
        card6Description:
          "Conecte-se com seu público-alvo através de estratégias de conteúdo e marketing digital. Evolua sua presença online com estratégias de SEO e performance."
      },
      cta: {
        title: "Não perca mais tempo",
        description:
          "Comece agora mesmo a transformação digital da sua empresa! Agende uma apresentação conosco."
      },
      siliconValley: {
        span: "Silicon Valley",
        title:
          "Nossa participação ativa no Vale do Silício desde 2014 nos permite oferecer soluções tecnológicas inovadoras e disruptivas",
        button: "Saber mais"
      },
      purpose: {
        span: "Próposito",
        title:
          "Descubra como estamos transformando o mundo através da tecnologia",
        title1: "Acreditamos que o futuro é...",
        description1:
          "definido por etapas ousadas e mudanças significativas. Para alcançar esse futuro, é necessário ter coragem para desafiar o presente e inovar. É através da inovação que se constrói um caminho para o sucesso e se alcança os resultados desejados. Acreditamos que a coragem para mudar e inovar é fundamental para a realização de grandes conquistas.",
        title2: "Buscamos por pessoas que...",
        description2:
          "desafiam o status quo são aquelas que enxergam além do presente e buscam construir o futuro agora. Elas são informadas sobre as tendências e inovações, e têm a coragem de enfrentar os desafios e mudanças necessárias para alcançar seus objetivos. Acreditamos que é através dessas pessoas e de suas ações disruptivas que é possível criar um mundo melhor e mais conectado. Por isso, incentivamos a busca constante pelo conhecimento e pela inovação, como forma de transformar o presente e construir um futuro mais promissor.",
        title3: "Definimos juntos a ambição de nossos...",
        description3:
          "clientes, pois acreditamos que a parceria e a colaboração são fundamentais para o sucesso. Com uma visão de futuro clara, trabalhamos em conjunto com um propósito comum de gerar valor e impulsionar a transformação digital em diferentes setores. Nosso objetivo é oferecer soluções que superem as expectativas e tragam resultados significativos para nossos clientes. Para isso, investimos em inovação, tecnologia e em uma equipe altamente qualificada e comprometida com a excelência. Juntos, vamos mais longe!",
        title4: "Temos paixão e compromisso por...",
        description4:
          "resultados excepcionais e ética nos negócios. Somos movidos pela satisfação de nossos clientes e comprometidos em entregar soluções personalizadas e inovadoras para atender às suas necessidades. Nossa equipe está empenhada em garantir o sucesso de cada projeto e construir relacionamentos duradouros com nossos clientes.",
        title5: "Simplifique suas decisões e conquiste...",
        description5:
          "resultados com agilidade. Nosso objetivo é simplificar o processo de tomada de decisões e tornar a conquista de resultados algo mais acessível e eficaz. Transformamos ideias em ação de maneira prática e ágil, utilizando metodologias e tecnologias avançadas para garantir o melhor desempenho em cada projeto. Sabemos que o maior desafio é manter a simplicidade em meio a complexidade, mas estamos comprometidos em superar esse desafio e entregar soluções eficientes aos nossos clientes.",
        title6: "Colaboração para alcançar o...",
        description6:
          "extraordinário. Nós acreditamos que a colaboração é a chave para alcançar resultados extraordinários. Trabalhamos como uma equipe única, em parceria com nossos clientes, para entender suas necessidades e desenvolver soluções personalizadas que superem suas expectativas. Juntos, conseguimos alcançar o extraordinário."
      },
      team: {
        span: "NOSSO TIME",
        title: "Conheça as áreas que compõem nossa equipe",
        card1Title: "Tecnologia",
        card1Description:
          "Nossa equipe oferece soluções personalizadas e inovadoras para cada cliente. Com arquitetos de software, desenvolvedores, QA e DevOps, garantimos a qualidade e eficiência das soluções.",
        card2Title: "Design",
        card2Description:
          "Nossa equipe de design cria experiências digitais únicas com UX/UI Designers e especialistas em Design Thinking, entendendo as necessidades dos clientes e entregando soluções eficientes.",
        card3Title: "Gestão",
        card3Description:
          "Nossa equipe ágil composta por Product Owner, Scrum Master e Agile Coach. Liderança e coordenação de projetos inovadores e complexos para resultados excepcionais."
      },
      methodology: {
        span: "Metadologia",
        title:
          "Nossos serviços e metadologia irão ajudar você e sua empresa a superar os desafios desta Transformação Digital"
      },
      contact: {
        span: "Contato",
        title:
          "Estamos ansiosos para conversar com você! Entre em contato conosco agora.",
        name: "Nome",
        placeholderName: "Digite seu nome",
        number: "Telefone",
        message: "Message",
        optional: " (Opcional)",
        placeholderMessage: "Escreva uma mensagem personalizada para gente...",
        button: "Enviar contato"
      },
      footer: {
        span: "Criamos mudanças que realmente importam",
        rights: "Sunndex Partners. Todos direitos reservados"
      }
    }
  }
};

export { messages };

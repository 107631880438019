// eslint-disable-next-line
const prod = {
  url: {
    apiEndPoint: "https://acessoenergia.com.br/api",
    urlLogout: "https://acessoenergia.com.br/logout"
  }
};
// eslint-disable-next-line
const dev = {
  url: {
    apiEndPoint: "http://localhost:8082/api",
    urlLogout: "http://localhost:3000/logout"
  }
};
export const config = dev;

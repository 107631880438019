//Importação React
import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Methodology() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    console.log(language);
  });

  return (
    <section className="bg-methodology object-cover" id="methodology">
      <div className="px-6 lg:px-14 2xl:px-36 py-16 ">
        <div className="flex flex-col  mb-6">
          <h3 className="text-lg md:text-xl font-normal uppercase tracking-widest text-white mb-3">
            {t("methodology.span")}
          </h3>
          <h2 className="text-white font-bold text-2xl md:text-4xl md:w-11/12 leading-normal">
            {t("methodology.title")}
          </h2>
        </div>
        <div className="flex items-center justify-center mt-8 md:mt-16">
          {language === "pt-BR" ? (
            <img src="/images/sections/methodology-pt.svg" alt="" />
          ) : (
            <img src="/images/sections/methodology-en.svg" alt="" />
          )}
        </div>
      </div>
    </section>
  );
}

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiMenu, FiX } from "react-icons/fi";
import { Link } from "react-scroll";

const I18N_STORAGE_KEY = "i18nextLng";

export default function Nav() {
  const [header, setHeader] = useState(false);
  const [navMobile, setNavMobile] = useState(false);
  const [active, setActive] = useState("");
  const [language, setLanguage] = useState(
    localStorage.getItem(I18N_STORAGE_KEY)
  );

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 80 ? setHeader(true) : setHeader(false);
    });
  });

  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  // const [language, setLanguage] = useState(
  //   localStorage.getItem(I18N_STORAGE_KEY)
  // );

  const handleLanguageChange = newLanguage => {
    localStorage.setItem(I18N_STORAGE_KEY, newLanguage);
    setSelectedLanguage(newLanguage);
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  const menuItem = [
    { nome: `${i18n.t("nav.home")}`, section: "home" },
    {
      nome: `${i18n.t("nav.services")}`,
      section: "services"
    },
    {
      nome: `${i18n.t("nav.siliconValley")}`,
      section: "siliconValley"
    },
    { nome: `${i18n.t("nav.purpose")}`, section: "purpose" },
    { nome: `${i18n.t("nav.team")}`, section: "team" },
    {
      nome: `${i18n.t("nav.methodology")}`,
      section: "methodology"
    }
  ];

  return (
    <>
      <nav
        className={`${
          header ? " px-5 py-5 shadow-md bg-neutral-900" : "py-8 "
        }  fixed w-full left-0 right-0 top-0 z-20 transition-all duration-500   px-6 lg:px-14 2xl:px-36`}
      >
        <div
          className={`${
            header ? "border-none border-b border-neutral-600" : " pb-5"
          } flex lg:block justify-between`}
        >
          <div className="flex items-center justify-between">
            <Link
              to="home"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className="cursor-pointer"
            >
              <img src="/images/logo/logo-sunndex.svg" alt="Logo Sunndex" />
            </Link>
            <div className="flex items-center gap-8">
              <ul className="hidden lg:flex items-center gap-8">
                {menuItem.map((item, index) => (
                  <li key={index}>
                    <Link
                      to={item.section}
                      spy={true}
                      smooth={true}
                      offset={-200}
                      duration={500}
                      onSetActive={() => setActive(`${item.section}`)}
                      className={`text-lg cursor-pointer ${
                        active === `${item.section}`
                          ? "text-primary font-bold"
                          : "text-neutral-400 hover:text-neutral-300"
                      }`}
                    >
                      {item.nome}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="hidden lg:flex lg:gap-8">
                <button className=" bg-neutral-800 rounded cursor-pointer hover:bg-neutral-700">
                  <Link
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                    className={`relative inline-flex group justify-center font-semibold text-lg text-neutral-200 items-center px-6 py-3 ransition-transform`}
                  >
                    <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-neutral-400 rounded group-hover:w-full group-hover:h-14 opacity-10"></span>
                    {i18n.t("nav.contact")}
                  </Link>
                </button>
                <div className="flex items-center gap-5">
                  <button onClick={() => handleLanguageChange("pt-BR")}>
                    <img
                      src="/images/icons/navbar/br-icon.svg"
                      alt="Bandeira do Brasil"
                      className={`text-lg cursor-pointer ${
                        language === "pt-BR"
                          ? "opacity-100"
                          : "opacity-60 hover:opacity-75"
                      }`}
                    />
                  </button>
                  <button onClick={() => handleLanguageChange("en-US")}>
                    <img
                      src="/images/icons/navbar/us-icon.svg"
                      alt="Bandeira do Estados Unidos"
                      className={`text-lg cursor-pointer ${
                        language === "en-US"
                          ? "opacity-100"
                          : "opacity-60 hover:opacity-75"
                      }`}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => setNavMobile(!navMobile)}
            className="lg:hidden cursor-pointer "
          >
            {navMobile ? (
              <FiX className="text-3xl text-neutral-300 " />
            ) : (
              <FiMenu className="text-3xl text-neutral-300 " />
            )}
          </div>
        </div>
        {navMobile ? (
          <div className="bg-neutral-800 border border-gray-600 p-5 rounded lg:invisible w-full mt-3">
            <div className="flex items-center justify-between">
              <ul className="  flex flex-col gap-3">
                {menuItem.map((item, index) => (
                  <li key={index}>
                    <Link
                      to={item.section}
                      spy={true}
                      smooth={true}
                      offset={-200}
                      duration={500}
                      onSetActive={() => setActive(`${item.section}`)}
                      className={`text-lg cursor-pointer ${
                        active === `${item.section}`
                          ? "text-primary font-bold"
                          : "text-neutral-400 hover:text-neutral-300"
                      }`}
                    >
                      {item.nome}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="flex  lg:hidden  mt-4 gap-5 ">
                <div className="flex items-center gap-5 flex-col">
                  <button onClick={() => handleLanguageChange("pt-BR")}>
                    <img
                      src="/images/icons/navbar/br-icon.svg"
                      alt="Bandeira do Brasil"
                    />
                  </button>
                  <button onClick={() => handleLanguageChange("en-US")}>
                    <img
                      src="/images/icons/navbar/us-icon.svg"
                      alt="Bandeira do Estados Unidos"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </nav>
    </>
  );
}

import React, { lazy, Suspense } from "react";
import "../../utils/scroll.css";
import Nav from "../../components/Nav";
import Header from "./components/Header";
import { I18nextProvider } from "react-i18next";
import { i18n } from "../../../translate/i18n";
import Services from "./components/Services";
import SiliconValley from "./components/SiliconValley";
import Purpose from "./components/Purpose";
import Team from "./components/Team";
import Methodology from "./components/Methodology";
import Contact from "../../components/Contact";
import CTA from "./components/CTA";
import Segments from "./components/Segments";
const Footer = lazy(() => import("../../components/Footer"));

export default function Home() {
  return (
    <I18nextProvider i18n={i18n}>
      <Suspense>
        <div className="scrollbar h-screen">
          {/* <ScrollToTop /> */}
          <div className="relative">
            <Nav /> <Header />{" "}
            <div className="gradient-02 z-0 hidden md:block" />
            <div className="gradient-03 z-0" />
          </div>
          <Segments />
          <div className="relative">
            <Services />
            <div className="gradient-04 z-0 hidden md:block" />
            <div className="gradient-05 z-0" />
          </div>
          <CTA />
          <div className="relative">
            <SiliconValley />
            <div className="gradient-05 z-0 hidden md:block" />
            <div className="gradient-04 z-0" />
          </div>

          <Purpose />
          <Team />
          <Methodology />
          <Contact />
          <Footer />
        </div>
      </Suspense>
    </I18nextProvider>
  );
}

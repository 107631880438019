const messages = {
  en: {
    translations: {
      nav: {
        home: "Home",
        services: "Services",
        siliconValley: "Silicon Valley",
        purpose: "Purpose",
        team: "Team",
        methodology: "Metadology",
        contact: "Contact"
      },
      home: {
        span: "Agile consulting, design and technology",
        title:
          "We create changes <br/> that really <span class='font-extrabold underline decoration-[#414141] decoration-auto'>matter</span>",
        text:
          "We turn <span class='text-neutral-200'>goals</span> into reality <span class='text-neutral-200'>quickly</span> and <span class='text-neutral-200'>sustainably</span>. We help organizations define directions and achieve their <span class='text-neutral-200'>goals</span> with <span class='text-neutral-200'>professionalism</span>",
        button: "Transform now"
      },
      segments: {
        span: "Principais Segmentos",
        title: "Descubra nossas áreas de atuação",
        segment1: "Insurance",
        segment2: "Retail",
        segment3: "Financial",
        segment4: "Services",
        segment5: "Manufacturing",
        segment6: "Energy"
      },
      services: {
        span: "Our services",
        title: "Get to know our services and technological solutions",
        card1Title: "Technology",
        card1Description:
          "We offer front-end, back-end and mobile services, as well as data analysis and APIs for system integration.",
        card2Title: "Internet",
        card2Description:
          "We develop websites, e-commerce and content portals, as well as strategies for the digital positioning of your company.",
        card3Title: "Digital XP",
        card3Description:
          "We create technological solutions with UX/UI Design, customer journey redesign, digital product development and front-end for your company.",
        card4Title: "Digital Products",
        card4Description:
          "We develop products for startups, web software engineering and agile development solutions to boost your company.",
        card5Title: "Digital Culture",
        card5Description:
          "Transform your company for the digital age with our disruption and future workshop, promoting a mindset shift culture and corporate education.",
        card6Title: "Engage and Evolve",
        card6Description:
          "Connect with your target audience through content and digital marketing strategies. Evolve your online presence with SEO and performance strategies."
      },
      cta: {
        title: "Don't waste more time",
        description:
          "Start the digital transformation of your company right now! Schedule a presentation with us."
      },
      siliconValley: {
        span: "Silicon Valley",
        title:
          "Our active participation in Silicon Valley since 2014 allows us to offer innovative and disruptive technological solutions",
        button: "Find out more"
      },
      purpose: {
        span: "Purpose",
        title: "Discover how we are transforming the world through technology",
        title1: "We believe that the future is...",
        description1:
          "defined by bold stages and significant changes. To achieve this future, it is necessary to have the courage to challenge the present and innovate. Through innovation, one builds a path to success and achieves desired results. We believe that the courage to change and innovate is fundamental for achieving great accomplishments.",
        title2: "We are looking for people who...",
        description2:
          "challenge the status quo are those who see beyond the present and seek to build the future now. They are informed about trends and innovations, and have the courage to face the challenges and necessary changes to achieve their goals. We believe that it is through these people and their disruptive actions that it is possible to create a better and more connected world. Therefore, we encourage the constant search for knowledge and innovation as a way to transform the present and build a more promising future.",
        title3: "We define our clients' ambition...",
        description3:
          "together, because we believe that partnership and collaboration are fundamental to success. With a clear future vision, we work together with a common purpose of generating value and driving digital transformation in different sectors. Our goal is to offer solutions that exceed expectations and bring significant results to our clients. To achieve this, we invest in innovation, technology, and a highly qualified team committed to excellence. Together, we can go further!",
        title4: "We have passion and commitment for...",
        description4:
          "exceptional results and ethics in business. We are driven by the satisfaction of our clients and committed to delivering personalized and innovative solutions to meet their needs. Our team is dedicated to ensuring the success of each project and building lasting relationships with our clients.",
        title5: "Simplify your decisions and conquer...",
        description5:
          "results with agility. Our goal is to simplify the decision-making process and make achieving results more accessible and effective. We turn ideas into action in a practical and agile way, using advanced methodologies and technologies to ensure the best performance in each project. We know that the biggest challenge is to maintain simplicity in the midst of complexity, but we are committed to overcoming this challenge and delivering efficient solutions to our clients.",
        title6: "Collaboration to achieve the...",
        description6:
          "extraordinary. We believe that collaboration is the key to achieving extraordinary results. We work as a single team, in partnership with our clients, to understand their needs and develop customized solutions that exceed their expectations. Together, we can achieve the extraordinary."
      },

      team: {
        span: "OUR TEAM",
        title: "Get to know the areas that make up our team",
        card1Title: "Technology",
        card1Description:
          "Our team provides customized and innovative solutions for each client. With software architects, developers, QA, and DevOps, we ensure the quality and efficiency of the solutions.",
        card2Title: "Design",
        card2Description:
          "Our design team creates unique digital experiences with UX/UI designers and Design Thinking specialists, understanding clients' needs and delivering efficient solutions.",
        card3Title: "Management",
        card3Description:
          "Our agile team is composed of a Product Owner, Scrum Master, and Agile Coach. They provide leadership and coordination for innovative and complex projects that deliver exceptional results."
      },
      methodology: {
        span: "Methodology",
        title:
          "Our services and methodology will help you and your company to overcome the challenges of this Digital Transformation"
      },
      contact: {
        span: "Contact",
        title: "We look forward to talking with you! Contact us now",
        name: "Name",
        placeholderName: "Type your name",
        number: "Phone number",
        message: "Message",
        optional: " (Optional)",
        placeholderMessage: "Write a personalized message to us",
        button: "Submit contact"
      },
      footer: {
        span: "We create changes that really matter",
        rights: "Sunndex Partners. All rights reserved"
      }
    }
  }
};

export { messages };

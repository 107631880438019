//Importação React
import React from "react";
import { useTranslation } from "react-i18next";
import { RiArrowRightUpLine } from "react-icons/ri";
import { Link } from "react-scroll";

export default function SiliconValley() {
  const { t } = useTranslation();

  return (
    <section className="px-6 lg:px-14 2xl:px-36 pt-40 pb-10" id="siliconValley">
      <div className="w-full flex flex-col gap-6 md:gap-10">
        <div className="">
          <h3 className="text-lg md:text-xl font-normal uppercase tracking-widest text-primary mb-3">
            {t("siliconValley.span")}
          </h3>
          <h2 className="text-neutral-200 font-bold text-2xl md:text-4xl leading-relaxed">
            {t("siliconValley.title")}
          </h2>
        </div>
        <div className="flex items-center gap-3 md:gap-7">
          <img
            src="/images/sections/sao-francisco-1.png"
            alt="Ponte de São Francisco"
            className="w-[34%] md:w-4/12"
          />
          <img
            src="/images/sections/sao-francisco-2.png"
            alt="Cidade de São Francisco"
            className="w-[63%] md:w-[60.5%]"
          />
        </div>
        <div className="flex justify-center items-center mt-3">
          <button className=" bg-[#D95B00] rounded cursor-pointer hover:bg-orange-700 transform hover:-translate-y-1 hover:scale-110 transition duration-500">
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className={`relative inline-flex group justify-center font-semibold text-sm md:text-lg text-neutral-200 items-center px-4 md:px-6 py-2 md:py-3 ransition-transform gap-3`}
            >
              {t("siliconValley.button")}
              <RiArrowRightUpLine className="w-4 h-4 md:w-6 md:h-6 text-neutral-100 hover:text-neutral-200" />
            </Link>
          </button>
        </div>
      </div>
    </section>
  );
}

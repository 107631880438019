//Importação React
import React from "react";
import { useTranslation } from "react-i18next";
import {
  RiServiceLine,
  RiStore2Line,
  RiBankLine,
  RiMailCheckLine,
  RiHomeGearLine,
  RiLightbulbFlashLine
} from "react-icons/ri";

export default function Segments() {
  const { t } = useTranslation();

  const card = [
    {
      icon: (
        <RiMailCheckLine className="text-[#888888] hover:text-neutral-500 w-5 h-5 md:w-6 md:h-6" />
      ),
      title: `${t("segments.segment4")}`
    },
    {
      icon: (
        <RiBankLine className="text-[#888888] hover:text-neutral-500 w-5 h-5 md:w-6 md:h-6" />
      ),
      title: `${t("segments.segment3")}`
    },
    {
      icon: (
        <RiServiceLine className="text-[#888888] hover:text-neutral-500 w-5 h-5 md:w-6 md:h-6" />
      ),
      title: `${t("segments.segment1")}`
    },
    {
      icon: (
        <RiStore2Line className="text-[#888888] hover:text-neutral-500 w-5 h-5 md:w-6 md:h-6" />
      ),
      title: `${t("segments.segment2")}`
    },

    {
      icon: (
        <RiHomeGearLine className="text-[#888888] hover:text-neutral-500 w-5 h-5 md:w-6 md:h-6" />
      ),
      title: `${t("segments.segment5")}`
    },

    {
      icon: (
        <RiLightbulbFlashLine className="text-[#888888] hover:text-neutral-500 w-5 h-5 md:w-6 md:h-6" />
      ),
      title: `${t("segments.segment6")}`
    }
  ];

  return (
    <section
      className="px-0 lg:px-14 2xl:px-36 bg-[#1E1E1E] py-6 md:py-10 mt-6"
      id="segments"
    >
      <div className=" w-full">
        {/* <h3 className="text-lg md:text-xl font-normal uppercase tracking-widest text-primary mb-3">
          {t("segments.span")}
        </h3> */}
        {/* <h2 className="text-neutral-600 font-normal text-xl text-center flex justify-center mb-10">
          {t("segments.title")}
        </h2> */}
        <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-6 gap-2 md:gap-10">
          {card.map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-center gap-2.5"
            >
              {item.icon}
              <h3 className="text-[#888888] hover:text-neutral-500 text-lg md:text-xl font-semibold text-center">
                {item.title}
              </h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

//Importação React
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";

export default function Header() {
  const { t } = useTranslation();

  return (
    <header className="px-6 lg:px-14 2xl:px-36 py-20 mt-20 md:mt-32" id="home">
      <div className="flex flex-col md:justify-center md:items-center w-full">
        <span className="text-xl md:text-2xl font-medium text-primary mb-4 md:mb-1">
          {t("home.span")}
        </span>
        <h1 className="text-5xl md:text-8xl font-bold text-neutral-200 md:text-center leading-tight">
          <div dangerouslySetInnerHTML={{ __html: t("home.title") }} />
        </h1>
        <p className="text-lg md:text-2xl text-neutral-400 font-normal mt-5 md:mt-7 md:text-center md:w-4/5">
          <div dangerouslySetInnerHTML={{ __html: t("home.text") }} />
        </p>
        <Link to="contact" spy={true} smooth={true} offset={0} duration={500}>
          <button className="flex items-center gap-6 mt-5 md:mt-10 transform hover:-translate-y-1 hover:scale-110 transition duration-500">
            <span className="text-neutral-200 font-medium text-2xl">
              {t("home.button")}
            </span>
            <img
              src="/images/icons/home/button-home.svg"
              alt="Botão home"
              className="w-16 h-16"
            />
          </button>
        </Link>
      </div>
    </header>
  );
}

//Importação React
import React from "react";
import { useTranslation } from "react-i18next";
import { RiArrowRightUpLine } from "react-icons/ri";
import { Link } from "react-scroll";

export default function CTA() {
  const { t } = useTranslation();

  return (
    <>
      <section className="bg-cta object-cover">
        <div className="px-6 lg:px-14 2xl:px-36 py-20 lg:py-40 flex flex-col lg:flex-row justify-between items-center">
          <div className="flex flex-col gap-4 lg:gap-6 ">
            <h2 className="font-bold text-neutral-50 text-4xl md:text-6xl lg:text-[82px] text-center lg:text-start">
              {t("cta.title")}
            </h2>
            <p className="text-neutral-100 text-3xl lg:text-5xl leading-snug max-w-4xl text-center lg:text-start">
              {t("cta.description")}
            </p>
          </div>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="flex items-center justify-center rounded-full bg-transparent border-4 border-neutral-50 p-8 cursor-pointer hover:border-neutral-200 transform hover:-translate-y-1 hover:scale-110 transition duration-500 mt-14 lg:mt-0"
          >
            <RiArrowRightUpLine className="w-16 h-16 lg:w-32 lg:h-32 text-neutral-100 hover:text-neutral-200" />
          </Link>
        </div>
      </section>
    </>
  );
}

import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import NumberFormat from "react-number-format";

const getFieldCSSClasses = (touched, errors) => {
  const classes = [
    "bg-neutral-800 w-full h-11 pl-3 pr-8 mt-2 text-sm font-regular text-neutral-200 border rounded border-neutral-600 hover:bg-neutral-700 focus:outline-none focus:border-primary focus:border-2 placeholder:text-neutral-400 focus:bg-neutral-800"
  ];
  if (touched && errors) {
    classes.push("border-2 border-red-500 mt-2");
  }

  if (touched === true && errors === undefined) {
    classes.push("border-2 border-green-500");
  }

  return classes.join(" ");
};

const getFieldCSSClasses2 = (touched, errors) => {
  const classes = [
    "w-full p-2.5 mt-1 text-sm font-regular text-gray-700 border rounded border-gray-200 hover:bg-gray-50 focus:outline-none focus:border-blue-400 focus:border-2 placeholder:text-gray-300"
  ];
  if (touched && errors) {
    classes.push("border border-red-500 mt-2");
  }

  if (touched === true && errors === undefined) {
    classes.push("border border-red-500");
  }

  return classes.join(" ");
};

const getFieldCSSClasses3 = (touched, errors) => {
  const classes = [
    "w-full p-2.5 text-sm font-regular text-gray-700 border-r border-l border-b rounded-br rounded-bl border-gray-200 hover:bg-gray-50 focus:outline-none focus:border-blue-400 focus:border-2 placeholder:text-gray-300"
  ];
  if (touched && errors) {
    classes.push("border border-red-500 mt-2");
  }

  if (touched === true && errors === undefined) {
    classes.push("border border-red-500");
  }

  return classes.join(" ");
};

const getFieldCSSClasses4 = (touched, errors) => {
  const classes = [
    "w-full p-2.5 text-sm font-regular text-gray-700 border-r  border-b rounded-br rounded-bl border-gray-200 hover:bg-gray-50 focus:outline-none focus:border-blue-400 focus:border-2 placeholder:text-gray-300"
  ];
  if (touched && errors) {
    classes.push("border border-red-500 mt-2");
  }

  if (touched === true && errors === undefined) {
    classes.push("border border-red-500");
  }

  return classes.join(" ");
};

export default function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  return (
    <>
      <div>
        {label && (
          <label
            htmlFor={label}
            className="text-neutral-200 text-sm font-medium"
          >
            {" "}
            {label}{" "}
          </label>
        )}
        <div className="">
          <input
            type={type}
            {...field}
            {...props}
            className={getFieldCSSClasses(
              touched[field.name],
              errors[field.name]
            )}
          />
          {withFeedbackLabel && errors[field.name] && (
            <FieldFeedbackLabel
              error={errors[field.name]}
              touched={touched[field.name]}
              label={label}
              type={type}
              customFeedbackLabel={customFeedbackLabel}
            />
          )}
        </div>
      </div>
    </>
  );
}

export function Input2({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  return (
    <>
      <div>
        {label && (
          <label
            htmlFor={label}
            className="text-neutral-200 text-sm font-medium"
          >
            {" "}
            {label}{" "}
          </label>
        )}
        <div className="">
          <input
            type={type}
            {...field}
            {...props}
            className={getFieldCSSClasses2(
              touched[field.name],
              errors[field.name]
            )}
          />
          {withFeedbackLabel && errors[field.name] && (
            <FieldFeedbackLabel
              error={errors[field.name]}
              touched={touched[field.name]}
              label={label}
              type={type}
              customFeedbackLabel={customFeedbackLabel}
            />
          )}
        </div>
      </div>
    </>
  );
}

export function Input3({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  return (
    <>
      <NumberFormat
        format="##/##"
        className={getFieldCSSClasses3(touched[field.name], errors[field.name])}
        {...field}
        {...props}
      />
      {withFeedbackLabel && errors[field.name] && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}

export function Input4({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  return (
    <>
      <NumberFormat
        format="###"
        className={getFieldCSSClasses4(touched[field.name], errors[field.name])}
        {...field}
        {...props}
      />
      {withFeedbackLabel && errors[field.name] && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}

export function InputMesesDados({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  return (
    <>
      {label && <label>{label}</label>}
      <NumberFormat
        suffix={" meses"}
        value={field.value}
        onValueChange={val => {
          form.setFieldValue(field.name, val.floatValue);
        }}
        className={getFieldCSSClasses(
          form.touched[field.name],
          form.errors[field.name]
        )}
        {...props}
      />
      {withFeedbackLabel && form.errors[field.name] && (
        <FieldFeedbackLabel
          error={form.errors[field.name]}
          touched={form.touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}

export function InputSazo({ field, form, label, type = "text", ...props }) {
  const { disabled } = props;

  function getDivCSSClasses() {
    const classes = [
      "flex items-center justify-center py-2 px-4 bg-gray-50 border-gray-200 border-b"
    ];

    if (label !== "dez") {
      classes.push("border-r");
    }

    return classes.join(" ");
  }

  function getFieldCSSClasses() {
    const classes = ["w-full border-gray-200"];

    if (label !== "dez") {
      classes.push("border-r");
    }

    return classes.join(" ");
  }

  return (
    <div className="flex flex-col">
      <div className={getDivCSSClasses()}>
        <span className="text-xs font-normal text-neutral-200">{label}</span>
      </div>
      <div className={getFieldCSSClasses()}>
        <NumberFormat
          decimalSeparator=","
          thousandSeparator="."
          value={field.value}
          onValueChange={val => {
            form.setFieldValue(field.name, val.floatValue);
          }}
          className={
            disabled
              ? "w-full text-xs font-medium text-gray-600 text-center border-0 ring-0 focus:ring-0 bg-gray-200"
              : "w-full text-xs font-medium text-gray-600 text-center border-0 ring-0 focus:ring-0"
          }
          {...props}
        />
      </div>
    </div>
  );
}

//Importação React
import React from "react";
import { useTranslation } from "react-i18next";
import {
  RiComputerLine,
  RiShoppingBasketLine,
  RiGlobalLine,
  RiPenNibLine,
  RiSmartphoneLine,
  RiGroupLine
} from "react-icons/ri";

export default function Services() {
  const { t } = useTranslation();

  const card = [
    {
      icon: <RiComputerLine className="text-neutral-500 w-8 h-8" />,
      title: `${t("services.card1Title")}`,
      description: `${t("services.card1Description")}`
    },
    {
      icon: <RiGlobalLine className="text-neutral-500 w-8 h-8" />,
      title: `${t("services.card2Title")}`,
      description: `${t("services.card2Description")}`
    },
    {
      icon: <RiPenNibLine className="text-neutral-500 w-8 h-8" />,
      title: `${t("services.card3Title")}`,
      description: `${t("services.card3Description")}`
    },
    {
      icon: <RiShoppingBasketLine className="text-neutral-500 w-8 h-8" />,
      title: `${t("services.card4Title")}`,
      description: `${t("services.card4Description")}`
    },
    {
      icon: <RiSmartphoneLine className="text-neutral-500 w-8 h-8" />,
      title: `${t("services.card5Title")}`,
      description: `${t("services.card5Description")}`
    },
    {
      icon: <RiGroupLine className="text-neutral-500 w-8 h-8" />,
      title: `${t("services.card6Title")}`,
      description: `${t("services.card6Description")}`
    }
  ];

  return (
    <section className="px-6 lg:px-14 2xl:px-36 py-24 md:py-40" id="services">
      <div className=" w-full">
        <h3 className="text-lg md:text-xl font-normal uppercase tracking-widest text-primary mb-3">
          {t("services.span")}
        </h3>
        <h2 className="text-neutral-200 font-bold text-3xl md:text-4xl">
          {t("services.title")}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 md:gap-10 mt-14">
          {card.map((item, index) => (
            <div className="bg-[#1E1E1E] p-10 flex flex-col gap-5 rounded-sm transform transition duration-700 hover:border-b-8 hover:border-primary">
              {item.icon}
              <h4 className="text-neutral-200 text-3xl font-medium">
                {item.title}
              </h4>
              <p className="text-neutral-400 font-normal text-base">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

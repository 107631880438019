import React from "react";
import { Switch, Route } from "react-router-dom";
import { Layout } from "../_layout/layout";
import BasePage from "./BasePage";
import ErrorsPage from "./error/ErrorsPage";
import { AnimatePresence } from "framer-motion/dist/framer-motion";

export function Routes() {
  return (
    <AnimatePresence>
      <Switch>
        <Route path="/error" component={ErrorsPage} />
        <Layout>
          <BasePage />
        </Layout>
      </Switch>
    </AnimatePresence>
  );
}

//Importação React
import React from "react";
import { useTranslation } from "react-i18next";
import { RiFileChartFill, RiPaintFill, RiServerFill } from "react-icons/ri";

export default function Team() {
  const { t } = useTranslation();

  const card = [
    {
      icon: <RiServerFill className="text-neutral-500 w-8 h-8" />,
      title: `${t("team.card1Title")}`,
      description: `${t("team.card1Description")}`
    },
    {
      icon: <RiPaintFill className="text-neutral-500 w-8 h-8" />,
      title: `${t("team.card2Title")}`,
      description: `${t("team.card2Description")}`
    },
    {
      icon: <RiFileChartFill className="text-neutral-500 w-8 h-8" />,
      title: `${t("team.card3Title")}`,
      description: `${t("team.card3Description")}`
    }
  ];

  return (
    <section className="px-6 lg:px-14 2xl:px-36 py-24 pt-20 pb-40" id="team">
      <div className=" w-full">
        <h3 className="text-lg md:text-xl font-normal uppercase tracking-widest text-primary mb-3">
          {t("team.span")}
        </h3>
        <h2 className="text-neutral-200 font-bold text-3xl md:text-4xl">
          {t("team.title")}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 md:gap-20 mt-20">
          {card.map((item, index) => (
            <div
              key={index}
              className=" flex flex-col gap-5 justify-center items-center self-start"
            >
              {item.icon}
              <h4 className="text-neutral-200 text-3xl font-medium text-center">
                {item.title}
              </h4>
              <p className="text-neutral-400 font-normal text-base text-center">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
